import type {
  ANCHOR_AGREEMENT_STATUS,
  COLLATERAL_TYPE,
  CURRENCY_TYPE,
  DEALER_IDENTIFIER_TYPE,
  LOAN_OPTION,
} from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { sliceZeroDecimal } from 'utils/formatter';

export interface AnchorAgreementVO {
  anchorAgreementId: number;
  contractNo: string;
  currencyType: CURRENCY_TYPE;
  collateralType: COLLATERAL_TYPE;
  dealerIdentifierType: DEALER_IDENTIFIER_TYPE;
  anchorAgreementStatus: ANCHOR_AGREEMENT_STATUS;
  startDate: string;
  expiryDate: string;
  changeDate: string;
  financierId: number;
  financierName: string;
  financierClientId: number;
  financierClientCode: string;
  financierClientName: string;
  financierClientBusinessCode: string;
  financierClientTaxCode: string;
  branchId: number;
  branchCode: string;
  branchName: string;
  branchTelephone: string;
  branchAddress: string;
  branchFax: string;
  branchManagerCode: string;
  branchManagerName: string;
  branchManagerMobile: string;
  branchManagerEmail: string;
  loanLimitAmount: string;
  partiallyRepaidPrincipalAmount: string;
  loanOption: LOAN_OPTION;
  settlementDateOverAllowable: boolean;
  referenceNumberDuplicationCheck: boolean;
  billOfExchangeNoRequired: boolean;
  maxLtvRatio: number;
  paymentSupport: boolean;
  settlementAccountFinancierCode: string;
  settlementAccountFinancierName: string;
  settlementAccountBranchCode: string;
  settlementAccountBranchName: string;
  settlementAccount: string;
  settlementAccountOwner: string;
  tempArSummaryExists: boolean;
  financierClientAutoSignUpForAnchorAdminOfVendorFinance: boolean;
}

export interface AnchorAgreementVOModel extends AnchorAgreementVO {
  loanLimitAmount: BigNumber;
  partiallyRepaidPrincipalAmount: BigNumber;
}

export function formattingToAnchorAgreementVOModel(data: AnchorAgreementVO): AnchorAgreementVOModel {
  const { loanLimitAmount, partiallyRepaidPrincipalAmount } = data;

  return {
    ...data,
    loanLimitAmount: sliceZeroDecimal(loanLimitAmount),
    partiallyRepaidPrincipalAmount: sliceZeroDecimal(partiallyRepaidPrincipalAmount),
  };
}
