import type { CURRENCY_TYPE, FINANCIER_INTERFACE_TYPE, SUPPORTED_COLLATERAL_TYPE } from 'enums';
import type { SignInModel } from 'models/SignInModel';
import type { FinancierSupportVOModel } from 'models/vo/FinancierSupportVO';

const SIGN_IN_KEY = 'SIGN_IN';
const LOGO_KEY = 'LOGO';
const ZOOM_KEY = 'ZOOM';
const MANAGE_UPLOADED_PARTNER_SHOW = 'MANAGE_UPLOADED_PARTNER_SHOW';
const LOAN_REPAYMENT_AGGREGATION = 'LOAN_REPAYMENT_AGGREGATION';
const SESSION_DURATION_TIME = 'SESSION_DURATION_TIME';
const SESSION_DURATION_NEED_RESET = 'SESSION_DURATION_NEED_RESET';
const CSS_KEY = 'CSS';
const LANGUAGE_KEY = 'LANGUAGE';
const COLLATERAL_TYPE_KEY = 'COLLATERAL_TYPE';
const CURRENCY_TYPE_KEY = 'CURRENCY_TYPE';
const MSW_DEV_TOOL_OPEN_KEY = 'MSW_DEV_TOOL_OPEN';
const IS_MSW_USED_KEY = 'IS_MSW_USED';
const AR_COMMISSION_ENABLE = 'AR_COMMISSION_ENABLE';
const FACTORING_ENABLE = 'FACTORING_ENABLE';
export function getCss(): string | null {
  return window.localStorage.getItem(CSS_KEY);
}

export function setCss(data: string) {
  window.localStorage.setItem(CSS_KEY, data);
}

export function getLanguage(): string | null {
  return window.localStorage.getItem(LANGUAGE_KEY);
}

export function setLanguage(data: string) {
  window.localStorage.setItem(LANGUAGE_KEY, data);
}

export function setSignIn(signInModel: SignInModel) {
  window.localStorage.setItem(SIGN_IN_KEY, JSON.stringify(signInModel));
}

export function getSignIn(): SignInModel | null {
  const jsonString = window.localStorage.getItem(SIGN_IN_KEY);
  try {
    return jsonString ? JSON.parse(jsonString) : null;
  } catch (e) {
    return null;
  }
}

export function removeSignIn() {
  if (!window) {
    throw new Error('cannot use window object');
  }
  window.localStorage.removeItem(SIGN_IN_KEY);
  window.localStorage.removeItem(LOGO_KEY);
  window.localStorage.removeItem(SESSION_DURATION_TIME);
  window.localStorage.removeItem(SESSION_DURATION_NEED_RESET);
  window.localStorage.removeItem(COLLATERAL_TYPE_KEY);
  window.localStorage.removeItem(CURRENCY_TYPE_KEY);
  window.localStorage.removeItem(MANAGE_UPLOADED_PARTNER_SHOW);
  window.localStorage.removeItem(LOAN_REPAYMENT_AGGREGATION);
  window.localStorage.removeItem(MSW_DEV_TOOL_OPEN_KEY);
  window.localStorage.removeItem(IS_MSW_USED_KEY);
  window.localStorage.removeItem(AR_COMMISSION_ENABLE);
  window.localStorage.removeItem(FACTORING_ENABLE);
}

export function setFinancierSupportData(data: Readonly<FinancierSupportVOModel>) {
  setCollateralType(data.supportedCollateralType);
  setCurrencyType(data.supportedCurrencyTypes);
  setLogo(data.logo);
  setPotentialPartnerRegistrable(data.potentialPartnerRegistrable);
  setLoanRepaymentAggregationEnabled(data.loanRepaymentAggregationEnabled);
  setArCommissionEnable(data.arCommissionEnable);
  setFactoringEnable(data.factoringEnable);
}

export function setCollateralType(data: SUPPORTED_COLLATERAL_TYPE) {
  window.localStorage.setItem(COLLATERAL_TYPE_KEY, data);
}

export function getCollateralType(): string | null {
  return window.localStorage.getItem(COLLATERAL_TYPE_KEY);
}

export function removeCollateralType() {
  window.localStorage.removeItem(COLLATERAL_TYPE_KEY);
}

export function setCurrencyType(data: CURRENCY_TYPE[]) {
  const currencyTypes = data ? data : [];
  window.localStorage.setItem(CURRENCY_TYPE_KEY, JSON.stringify(currencyTypes));
}

export function getCurrencyType() {
  const supportedCurrencyTypes = window.localStorage.getItem(CURRENCY_TYPE_KEY);

  return supportedCurrencyTypes ? JSON.parse(supportedCurrencyTypes) : null;
}

export function getLogo(): string | null {
  return window.localStorage.getItem(LOGO_KEY);
}

export function setLogo(data: string) {
  window.localStorage.setItem(LOGO_KEY, data);
}

export function removeLogo() {
  window.localStorage.removeItem(LOGO_KEY);
}

export function getZoom(): string | null {
  return window.localStorage.getItem(ZOOM_KEY);
}

export function setZoom(data: string) {
  window.localStorage.setItem(ZOOM_KEY, data);
}

export function removeZoom() {
  window.localStorage.removeItem(ZOOM_KEY);
}

export function setPotentialPartnerRegistrable(potentialPartnerRegistrable?: boolean) {
  if (!window) {
    throw new Error('cannot use window object');
  }

  if (potentialPartnerRegistrable)
    window.localStorage.setItem(MANAGE_UPLOADED_PARTNER_SHOW, String(potentialPartnerRegistrable));
}

export function getPotentialPartnerRegistrable(): boolean {
  return window.localStorage.getItem(MANAGE_UPLOADED_PARTNER_SHOW) === 'true';
}

export function setLoanRepaymentAggregationEnabled(loanRepaymentAggregationEnabled?: boolean) {
  if (!window) {
    throw new Error('cannot use window object');
  }

  if (loanRepaymentAggregationEnabled)
    window.localStorage.setItem(LOAN_REPAYMENT_AGGREGATION, String(loanRepaymentAggregationEnabled));
}

export function getLoanRepaymentAggregationEnabled(): boolean {
  return window.localStorage.getItem(LOAN_REPAYMENT_AGGREGATION) === 'true';
}
export function setSessionDurationTime(sessionDurationTime: number): void {
  // IO 감안하여 1초 Sub
  window.localStorage.setItem(SESSION_DURATION_TIME, String(sessionDurationTime - 1));
}

export function getSessionDurationTime(): number {
  const sessionDurationTime = window.localStorage.getItem(SESSION_DURATION_TIME);

  return parseInt(sessionDurationTime ?? '0');
}

export function setSessionDurationNeedReset(isNeed: boolean): void {
  window.localStorage.setItem(SESSION_DURATION_NEED_RESET, String(isNeed));
}

export function getSessionDurationNeedReset(): string | null {
  return window.localStorage.getItem(SESSION_DURATION_NEED_RESET);
}

// FIXME: utils func로 이동
export function checkInterfaceType(interfaceTypes: FINANCIER_INTERFACE_TYPE[] | FINANCIER_INTERFACE_TYPE): boolean {
  if (!Array.isArray(interfaceTypes)) {
    interfaceTypes = [interfaceTypes];
  }

  let signInInfo = getSignIn();

  if (!signInInfo) {
    signInInfo = { financierInterfaceSettingList: [] } as unknown as SignInModel;
  }

  return signInInfo.financierInterfaceSettingList.some(value => {
    return interfaceTypes.includes(value.financierInterfaceType) && value.enabled;
  });
}

export const getMswDevToolOpen = () => {
  return window.localStorage.getItem(MSW_DEV_TOOL_OPEN_KEY) === 'true';
};

export const setMswDevToolOpen = (isOpen: boolean) => {
  window.localStorage.setItem(MSW_DEV_TOOL_OPEN_KEY, String(isOpen));
};

export const setIsMswUsedOfStorage = (isMswUsed: boolean) => {
  window.localStorage.setItem(IS_MSW_USED_KEY, String(isMswUsed));
};

export function setArCommissionEnable(enable?: boolean) {
  if (!window) {
    throw new Error('cannot use window object');
  }

  if (enable) window.localStorage.setItem(AR_COMMISSION_ENABLE, String(enable));
}

export function getArCommissionEnable(): boolean {
  return window.localStorage.getItem(AR_COMMISSION_ENABLE) === 'true';
}

export function setFactoringEnable(enable?: boolean) {
  if (!window) {
    throw new Error('cannot use window object');
  }

  if (enable) window.localStorage.setItem(FACTORING_ENABLE, String(enable));
}

export function getFactoringEnable(): boolean {
  return window.localStorage.getItem(FACTORING_ENABLE) === 'true';
}
