import type React from 'react';

import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from 'components/stateless/Button/IconButton';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import QuestionButton from 'components/stateless/QuestionButton';

export interface HeaderType {
  headerText?: string | JSX.Element;
  colWidths?: number;
  className?: string;
  hasStatusDescription?: boolean;
  showStatusDescriptionFunc?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  hasCheckBox?: boolean;
  handleClickAllCheckBox?: Function;
  checkBoxId?: string;
  allCheckedPages?: number[];
  isAllChecked?: boolean;
  hasSort?: boolean;
  sortFunc?: Function;
  required?: boolean;
}

export interface BigHeaderType {
  headerText: string;
  colSpan: number;
  rowSpan?: number;
  className?: string;
}

interface TableHeaderProps {
  header: HeaderType[];
  bigHeader?: BigHeaderType[];
  backGroundType?: BackGroundType;
}

const TableHeader = ({ header, backGroundType = BackGroundType.Gray, bigHeader }: TableHeaderProps) => {
  return (
    <>
      <colgroup>
        {header.map((value, index) => (
          <col key={index} style={{ width: `${value.colWidths}px` }} />
        ))}
      </colgroup>
      <thead>
        {bigHeader && (
          <tr>
            {bigHeader.map((bigHeaderItems, index) => (
              <th
                scope="col"
                key={index}
                colSpan={bigHeaderItems.colSpan}
                rowSpan={bigHeaderItems?.rowSpan}
                className={bigHeaderItems.className ? bigHeaderItems.className : backGroundType}
              >
                {bigHeaderItems.headerText}
              </th>
            ))}
          </tr>
        )}
        <tr>
          {header.map((value, index) => (
            <th scope="col" key={index} className={`${value.className ? value.className : backGroundType}`}>
              {value.headerText}
              {value.required && <span className="star">*</span>}
              {value.hasStatusDescription && <QuestionButton onClick={value.showStatusDescriptionFunc} />}
              {value.hasCheckBox && (
                <div className="text-center">
                  <input
                    id={value?.checkBoxId}
                    className="form-check-input m-0"
                    type="checkbox"
                    onChange={e => {
                      value.handleClickAllCheckBox !== undefined && value.handleClickAllCheckBox(e);
                    }}
                    checked={
                      (value?.isAllChecked || value?.allCheckedPages?.includes(Number(value?.checkBoxId))) ?? false
                    }
                  />
                </div>
              )}
              {value.hasSort && (
                <IconButton className="sort-button" onClick={() => value.sortFunc && value.sortFunc()}>
                  <FontAwesomeIcon icon={faSort} />
                </IconButton>
              )}
            </th>
          ))}
        </tr>
      </thead>
    </>
  );
};

export default TableHeader;
