import { CURRENCY_TYPE, SUPPORTED_COLLATERAL_TYPE, stringToEnum } from 'enums';

export interface FinancierSupportVO {
  supportedCollateralType: string;
  supportedCurrencyTypes: string[];
  potentialPartnerRegistrable: boolean;
  factoringEnable: boolean;
  iseSignatureEnable: boolean;
  arCommissionEnable: boolean;
  logo: string;
  loanRepaymentAggregationEnabled: boolean;
}

export interface FinancierSupportVOModel extends FinancierSupportVO {
  supportedCollateralType: SUPPORTED_COLLATERAL_TYPE;
  supportedCurrencyTypes: CURRENCY_TYPE[];
}

export function formattingToFinancierSupportVOModel(data: FinancierSupportVO): FinancierSupportVOModel {
  return {
    ...data,
    supportedCollateralType: stringToEnum(SUPPORTED_COLLATERAL_TYPE, data.supportedCollateralType),
    supportedCurrencyTypes: data.supportedCurrencyTypes?.map(item => stringToEnum(CURRENCY_TYPE, item)),
  };
}
