import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { ANCHOR_AGREEMENT_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { requestAnchorAgreementsList } from 'utils/http/api/anchor/anchor-agreements';
import useModal from 'utils/modal/useModal';
import { getFormSearchParamValue, updateSearchParams } from 'utils/searchParams';

const AC_ANCHOR_AGREEMENT_LIST_QS_KEY = 'ac-anchor-agreement-list';

function AnchorAgreementList() {
  const modal = useModal();
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable(AC_ANCHOR_AGREEMENT_LIST_QS_KEY);
  const [anchorAgreementList, setAnchorAgreementList] = useState<Pageable<AnchorAgreementVOModel[]>>();
  const [searchAgreementNumber, setSearchAgreementNumber] = useState<string>('');

  useEffect(() => {
    if (mounted) {
      setSearchAgreementNumber(getFormSearchParamValue('contractNo'));
      fetchAcAgreementList(pageable.currentPage, pageable.sizePerPage, getFormSearchParamValue('contractNo'));
    }

    return () => {};
  }, [mounted]);

  async function fetchAcAgreementList(pageNumber: number = 1, sizePerPage: number = 10, searchData: string) {
    try {
      const anchorAgreementPage = await requestAnchorAgreementsList(pageNumber, sizePerPage, {
        contractNo: searchData,
      });

      updateSearchParams(
        { contractNo: searchData, pageNumber, rowCount: sizePerPage },
        AC_ANCHOR_AGREEMENT_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorAgreementList(anchorAgreementPage);
        setPageable(anchorAgreementPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const paginate = (page: number, sizePerPage: number) => {
    fetchAcAgreementList(page, sizePerPage, getFormSearchParamValue('contractNo'));
  };

  const onBlurUpdateAgreementNumber = (e: any) => {
    setSearchAgreementNumber(e.target.value);
  };

  const onClickSearch = (e: any) => {
    e.preventDefault();
    fetchAcAgreementList(1, pageable.sizePerPage, searchAgreementNumber);
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financier_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 150,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Responsible_Bank_Branch'),
      colWidths: 180,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Expired_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Status'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    const getAnchorAgreementStatusClassName = (anchorAgreementStatus: ANCHOR_AGREEMENT_STATUS) => {
      switch (anchorAgreementStatus) {
        case ANCHOR_AGREEMENT_STATUS.ACTIVATED:
          return 'text-bold-dark-green';
        case ANCHOR_AGREEMENT_STATUS.SUSPENDED:
          return 'text-bold-brick-red';
        default:
          return '';
      }
    };

    return anchorAgreementList?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
          <Td data={item.financierName} />
          <Td data={item.contractNo} />
          <Td data={item.currencyType} />
          <Td data={item.branchName} />
          <Td data={item.startDate} format="date" />
          <Td data={item.expiryDate} format="date" />
          <Td
            data={t(`code:anchor-agreement-status.${item?.anchorAgreementStatus}`)}
            className={getAnchorAgreementStatusClassName(item.anchorAgreementStatus)}
            format="code"
          />
          <TdLink path={ROUTES_AC.COMPANY.AGREEMENT_DETAIL_BUILD_PATH(item.anchorAgreementId)} />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Master_Agreement')} />
      <div className="content-area">
        <SectionTitle title={t('text:Anchor_Master_Agreement_List')} />
        <form>
          <div className="d-flex information-filter mt-0">
            <input
              className="only-input"
              type="text"
              placeholder={t('text:Anchor_Master_Agreement_Number')}
              onBlur={onBlurUpdateAgreementNumber}
              style={{ width: '220px' }}
              defaultValue={searchAgreementNumber}
            />
            <Button onClick={onClickSearch}>{t('text:Search')}</Button>
          </div>
        </form>

        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorAgreementList;
