import { DAY_COUNT_CONVENTION, FINANCIER_TYPE, SUPPORTED_COLLATERAL_TYPE, stringToEnum } from 'enums';

export interface FinancierCommonSettingVO {
  financierCommonSettingId: number;
  financierId: number;
  financierName: string;
  financierType: string;
  attachmentId: number;
  attachmentPath: string;
  attachmentFileName: string;
  settlementDateIsTodayInvoiceUploadable: boolean;
  supportedCollateralType: string;
  potentialPartnerRegistrable: boolean;
  communicatorRootPath: string;
  financierSubType: string;
  minimumPeriodForVendorFinancingEarlyRepayment: number;
  minimumPeriodForDealerFinancingEarlyRepayment: number;
  disburseDateBusinessDayConvention: number;
  repaymentDateBusinessDayConvention: number;
  invoiceWillBeSettledOnUploadDate: boolean;
  availableCurrentDisburseBusinessDate: string;
  minimumPeriodForRegisterAr: number;
  maximumPeriodForRegisterAr: number;
  subDomain: string;
  showOnlyAllowedBranchData: boolean;
  divisionRegistrable: boolean;
  vendorDayCountConvention: string;
  dealerDayCountConvention: string;
  factoringEnable: boolean;
  eSignatureEnable: boolean;
  arCommissionEnable: boolean;
  invoiceFinancingAmountAdjustable: boolean;
}

export interface FinancierCommonSettingVOModel extends FinancierCommonSettingVO {
  financierType: FINANCIER_TYPE;
  supportedCollateralType: SUPPORTED_COLLATERAL_TYPE;
  vendorDayCountConvention: DAY_COUNT_CONVENTION;
  dealerDayCountConvention: DAY_COUNT_CONVENTION;
}

export function formattingToFinancierCommonSettingVOModel(
  data: FinancierCommonSettingVO,
): FinancierCommonSettingVOModel {
  return {
    ...data,
    financierType: stringToEnum(FINANCIER_TYPE, data.financierType),
    supportedCollateralType: stringToEnum(SUPPORTED_COLLATERAL_TYPE, data.supportedCollateralType),
    vendorDayCountConvention: stringToEnum(DAY_COUNT_CONVENTION, data.vendorDayCountConvention),
    dealerDayCountConvention: stringToEnum(DAY_COUNT_CONVENTION, data.dealerDayCountConvention),
  };
}
