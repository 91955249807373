import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

import Navigation from 'components/stateless/Navigation';
import { ROUTES_AC } from 'constants/routes/anchor';
import NotFoundPage from 'pages/NotFoundPage';
import AnchorAgreementDetail from 'pages/anchor/company/agreement/detail/AnchorAgreementDetail';
import AnchorAgreementList from 'pages/anchor/company/agreement/list/AnchorAgreementList';
import AnchorUserDetail from 'pages/anchor/company/user/detail/AnchorUserDetail';
import AnchorUserList from 'pages/anchor/company/user/list/AnchorUserList';
import AnchorUserRegister from 'pages/anchor/company/user/register/AnchorUserRegister';
import AnchorDashboard from 'pages/anchor/dashboard/AnchorDashboard';
import AnchorDealerCompanyInfoConfirmedDetailPage from 'pages/anchor/manage-dealer/company-info/detail/AnchorDealerCompanyInfoConfirmedDetailPage';
import AnchorDealerCompanyInfoListPage from 'pages/anchor/manage-dealer/company-info/list/AnchorDealerCompanyInfoListPage';
import AnchorDealerCompanyInfoWaitingDetailPage from 'pages/anchor/manage-dealer/company-info/waiting-detail/AnchorDealerCompanyInfoWaitingDetailPage';
import AnchorDealerRegistrationConfirmedDetailPage from 'pages/anchor/manage-dealer/registration/detail/AnchorDealerRegistrationConfirmedDetailPage';
import AnchorDealerRegistrationWaitingDetailPage from 'pages/anchor/manage-dealer/registration/detail/AnchorDealerRegistrationWaitingDetailPage';
import AnchorDealerRegistrationListPage from 'pages/anchor/manage-dealer/registration/list/AnchorDealerRegistrationListPage';
import AnchorDealerRegistrationStep1Page from 'pages/anchor/manage-dealer/registration/step1/AnchorDealerRegistrationStep1Page';
import AnchorDealerRegistrationStep2Page from 'pages/anchor/manage-dealer/registration/step2/AnchorDealerRegistrationStep2Page';
import AnchorPartnerBankAccountConfirmedDetail from 'pages/anchor/manage-partner/bank-account/detail/AnchorPartnerBankAccountConfirmedDetail';
import AnchorPartnerBankAccountWaitingDetail from 'pages/anchor/manage-partner/bank-account/detail/AnchorPartnerBankAccountWaitingDetail';
import AnchorPartnerBankAccountList from 'pages/anchor/manage-partner/bank-account/list/AnchorPartnerBankAccountList';
import AnchorPartnerCompanyInfoConfirmedDetail from 'pages/anchor/manage-partner/company-info/detail/AnchorPartnerCompanyInfoConfirmedDetail';
import AnchorPartnerCompanyInfoWaitingDetail from 'pages/anchor/manage-partner/company-info/detail/AnchorPartnerCompanyInfoWaitingDetail';
import AnchorPartnerCompanyInfoList from 'pages/anchor/manage-partner/company-info/list/AnchorPartnerCompanyInfoList';
import AnchorPartnerRegistrationConfirmedDetail from 'pages/anchor/manage-partner/registration/detail/AnchorPartnerRegistrationConfirmedDetail';
import AnchorPartnerRegistrationWaitingDetail from 'pages/anchor/manage-partner/registration/detail/AnchorPartnerRegistrationWaitingDetail';
import AnchorPartnerRegistrationList from 'pages/anchor/manage-partner/registration/list/AnchorPartnerRegistrationList';
import AnchorPartnerRegistrationStep1 from 'pages/anchor/manage-partner/registration/step1/AnchorPartnerRegistrationStep1';
import AnchorPartnerRegistrationStep2 from 'pages/anchor/manage-partner/registration/step2/AnchorPartnerRegistrationStep2';
import AnchorMyAccount from 'pages/anchor/my-account/AnchorMyAccount';
import AnchorMyAccountEmailNotificationSetting from 'pages/anchor/my-account/email-notification-setting';
import AnchorApConfirmationDetail from 'pages/anchor/register-ap/confirmation/detail/AnchorApConfirmationDetail';
import AnchorApConfirmationList from 'pages/anchor/register-ap/confirmation/list/AnchorApConfirmationList';
import AnchorApRegisterStep1 from 'pages/anchor/register-ap/registration/step1/AnchorApRegisterStep1';
import AnchorApRegisterStep2 from 'pages/anchor/register-ap/registration/step2/AnchorApRegisterStep2';
import AnchorInvoiceConfirmationDetail from 'pages/anchor/register-invoices/confirmation/detail/AnchorInvoiceConfirmationDetail';
import AnchorInvoiceConfirmationList from 'pages/anchor/register-invoices/confirmation/list/AnchorInvoiceConfirmationList';
import AnchorInvoiceRegistrationByPartnerStep1 from 'pages/anchor/register-invoices/registeration-by-partner/step1/AnchorInvoiceRegistrationByPartnerStep1';
import AnchorInvoiceRegistrationByPartnerStep2 from 'pages/anchor/register-invoices/registeration-by-partner/step2/AnchorInvoiceRegistrationByPartnerStep2';
import AnchorInvoiceRegistrationStep1 from 'pages/anchor/register-invoices/registration/step1/AnchorInvoiceRegistrationStep1';
import AnchorInvoiceRegistrationStep2 from 'pages/anchor/register-invoices/registration/step2/AnchorInvoiceRegistrationStep2';
import AnchorApDetail from 'pages/anchor/view-transactions/ap/detail/AnchorApDetail';
import AnchorApList from 'pages/anchor/view-transactions/ap/list/AnchorApList';
import AnchorApSettlementDetail from 'pages/anchor/view-transactions/ap-settlement/detail/AnchorApSettlementDetail';
import AnchorApSettlementList from 'pages/anchor/view-transactions/ap-settlement/list/AnchorApSettlementList';
import AnchorDeptSellingNoticeDetail from 'pages/anchor/view-transactions/dept-selling-notice/detail/AnchorDeptSellingNoticeDetail';
import AnchorDeptSellingNoticeList from 'pages/anchor/view-transactions/dept-selling-notice/list/AnchorDeptSellingNoticeList';
import AnchorFinancingList from 'pages/anchor/view-transactions/financing/list/AnchorFinancingList';
import AnchorInterestPaymentList from 'pages/anchor/view-transactions/interest-payment/AnchorInterestPaymentList';
import AnchorInvoiceDetail from 'pages/anchor/view-transactions/invoice/detail/AnchorInvoiceDetail';
import AnchorInvoiceList from 'pages/anchor/view-transactions/invoice/list/AnchorInvoiceList';
import AnchorInvoicePaymentList from 'pages/anchor/view-transactions/invoice-payment/list/AnchorInvoicePaymentList';
import AnchorOverviewByPartnerList from 'pages/anchor/view-transactions/overview-by-partner/AnchorOverviewByPartnerList';

function AnchorRouter({ location }: RouteComponentProps<{}>) {
  return (
    <div className="content-wrapper">
      <Navigation />
      <div className="page-content">
        <Switch>
          {/* DashBoard - 대시보드 페이지 */}
          <Route exact path={ROUTES_AC.DASHBOARD} component={AnchorDashboard} />

          {/* Dealer Registration */}
          <Route exact path={ROUTES_AC.MANAGE_DEALER.REGISTRATION_LIST} component={AnchorDealerRegistrationListPage} />

          <Route
            exact
            path={ROUTES_AC.MANAGE_DEALER.REGISTRATION_STEP1}
            component={AnchorDealerRegistrationStep1Page}
          />

          <Route
            exact
            path={ROUTES_AC.MANAGE_DEALER.REGISTRATION_STEP2}
            component={AnchorDealerRegistrationStep2Page}
          />

          <Route
            path={ROUTES_AC.MANAGE_DEALER.REGISTRATION_WAITING_DETAIL}
            component={AnchorDealerRegistrationWaitingDetailPage}
          />

          <Route
            path={ROUTES_AC.MANAGE_DEALER.REGISTRATION_DETAIL}
            component={AnchorDealerRegistrationConfirmedDetailPage}
          />

          {/* Dealer Company Info */}
          <Route exact path={ROUTES_AC.MANAGE_DEALER.COMPANY_INFO_LIST} component={AnchorDealerCompanyInfoListPage} />

          <Route
            path={ROUTES_AC.MANAGE_DEALER.COMPANY_INFO_WAITING_DETAIL}
            component={AnchorDealerCompanyInfoWaitingDetailPage}
          />

          <Route
            path={ROUTES_AC.MANAGE_DEALER.COMPANY_INFO_DETAIL}
            component={AnchorDealerCompanyInfoConfirmedDetailPage}
          />

          {/* Supply Chain Partner 등록 step1 */}
          <Route exact path={ROUTES_AC.MANAGE_PARTNER.REGISTRATION_STEP1} component={AnchorPartnerRegistrationStep1} />

          {/* Manage Supply Chain Partner 파트너 등록 목록 */}
          <Route path={ROUTES_AC.MANAGE_PARTNER.REGISTRATION_LIST} component={AnchorPartnerRegistrationList} />

          {/* Supply Chain Partner 등록 대기 상세 */}
          <Route
            path={ROUTES_AC.MANAGE_PARTNER.REGISTRATION_WAITING_DETAIL}
            component={AnchorPartnerRegistrationWaitingDetail}
          />

          {/* Supply Chain Partner 등록 완료 상세 */}
          <Route
            path={ROUTES_AC.MANAGE_PARTNER.REGISTRATION_CONFIRMED_DETAIL}
            component={AnchorPartnerRegistrationConfirmedDetail}
          />

          {/* Supply Chain Partner 등록 step2 */}
          <Route path={ROUTES_AC.MANAGE_PARTNER.REGISTRATION_STEP2} component={AnchorPartnerRegistrationStep2} />

          {/* Supply Chain Partner 은행 계좌 수정 목록 */}
          <Route exact path={ROUTES_AC.MANAGE_PARTNER.BANK_ACCOUNT_LIST} component={AnchorPartnerBankAccountList} />

          {/* Supply Chain Partner 은행 확인 대기 계좌 수정 상세 */}
          <Route
            path={ROUTES_AC.MANAGE_PARTNER.BANK_ACCOUNT_WAITING_DETAIL}
            component={AnchorPartnerBankAccountWaitingDetail}
          />

          {/* Supply Chain Partner 은행 완료 확인 계좌 수정 상세 */}
          <Route
            path={ROUTES_AC.MANAGE_PARTNER.BANK_ACCOUNT_CONFIRMED_DETAIL}
            component={AnchorPartnerBankAccountConfirmedDetail}
          />

          {/* Supply Chain Partner 기업 정보 수정 목록 */}
          <Route exact path={ROUTES_AC.MANAGE_PARTNER.COMPANY_INFO_LIST} component={AnchorPartnerCompanyInfoList} />

          {/* Supply Chain Partner 기업 정보 수정 대기 상세 */}
          <Route
            path={ROUTES_AC.MANAGE_PARTNER.COMPANY_INFO_WAITING_DETAIL}
            component={AnchorPartnerCompanyInfoWaitingDetail}
          />

          {/* Supply Chain Partner 기업 정보 수정 확인 상세 */}
          <Route
            path={ROUTES_AC.MANAGE_PARTNER.COMPANY_INFO_CONFIRMED_DETAIL}
            component={AnchorPartnerCompanyInfoConfirmedDetail}
          />

          {/* Register invoice - Registration - 앵커 선택 */}
          <Route
            exact
            path={ROUTES_AC.REGISTER_INVOICE.REGISTRATION_STEP1}
            component={AnchorInvoiceRegistrationStep1}
          />

          {/* Register invoice - Registration - 송장 정보 입력 */}
          <Route path={ROUTES_AC.REGISTER_INVOICE.REGISTRATION_STEP2} component={AnchorInvoiceRegistrationStep2} />

          {/* Register Invoice - Registration by Partner - Partner 선택 */}
          <Route
            exact
            path={ROUTES_AC.REGISTER_INVOICE.REGISTRATION_BY_PARTNER_STEP1}
            component={AnchorInvoiceRegistrationByPartnerStep1}
          />

          {/* Register Invoice - Registration by Partner - 송장 정보 입력 */}
          <Route
            path={ROUTES_AC.REGISTER_INVOICE.REGISTRATION_BY_PARTNER_STEP2}
            component={AnchorInvoiceRegistrationByPartnerStep2}
          />

          {/* Register invoice - Confirmation - 송장 등록 결재 목록 */}
          <Route exact path={ROUTES_AC.REGISTER_INVOICE.CONFIRMATION_LIST} component={AnchorInvoiceConfirmationList} />

          {/* Register invoice - Confirmation - 송장 결재 상세 */}
          <Route
            key={location.key}
            path={ROUTES_AC.REGISTER_INVOICE.CONFIRMATION_DETAIL}
            component={AnchorInvoiceConfirmationDetail}
          />

          {/* AP 등록 step1 - anchor agreement 선택 */}
          <Route exact path={ROUTES_AC.REGISTER_AP.REGISTRATION_STEP1} component={AnchorApRegisterStep1} />

          {/* AP 등록 step2 - ap register page */}
          <Route path={ROUTES_AC.REGISTER_AP.REGISTRATION_STEP2} component={AnchorApRegisterStep2} />

          {/* AP confirmation list */}
          <Route exact path={ROUTES_AC.REGISTER_AP.CONFIRMATION_LIST} component={AnchorApConfirmationList} />

          {/* AP confirmation detail */}
          <Route
            key={location.key}
            path={ROUTES_AC.REGISTER_AP.CONFIRMATION_DETAIL}
            component={AnchorApConfirmationDetail}
          />

          {/* View Transactions - Invoice - 등록 완료 송장 목록 */}
          <Route exact path={ROUTES_AC.VIEW_TRANSACTION.INVOICE_LIST} component={AnchorInvoiceList} />

          {/* View Transactions - Invoice - 등록 완료 송장 상세 */}
          <Route path={ROUTES_AC.VIEW_TRANSACTION.INVOICE_DETAIL} component={AnchorInvoiceDetail} />

          {/* View Transactions - Invoice Payment - BOE 단위 송장 목록 */}
          <Route exact path={ROUTES_AC.VIEW_TRANSACTION.INVOICE_PAYMENT_LIST} component={AnchorInvoicePaymentList} />

          {/* View Transactions - AP - AP 목록 */}
          <Route exact path={ROUTES_AC.VIEW_TRANSACTION.AP_LIST} component={AnchorApList} />

          {/* View Transactions - AP - AP 상세 */}
          <Route path={ROUTES_AC.VIEW_TRANSACTION.AP_DETAIL} component={AnchorApDetail} />

          {/* View Transactions - AP - AP Settlement 목록 */}
          <Route exact path={ROUTES_AC.VIEW_TRANSACTION.AP_SETTLEMENT_LIST} component={AnchorApSettlementList} />

          {/* View Transactions - AP - AP Settlement 상세 */}
          <Route path={ROUTES_AC.VIEW_TRANSACTION.AP_SETTLEMENT_DETAIL} component={AnchorApSettlementDetail} />

          {/* View Transactions - Financing - 대출 목록 */}
          <Route exact path={ROUTES_AC.VIEW_TRANSACTION.FINANCING_LIST} component={AnchorFinancingList} />

          {/* View Transactions - Interest Payment - 파트너 이자 납부 조회 */}
          <Route exact path={ROUTES_AC.VIEW_TRANSACTION.INTEREST_PAYMENT_LIST} component={AnchorInterestPaymentList} />

          {/* View Transactions - Transaction by dealer - 딜러 모니터링 */}
          <Route
            exact
            path={ROUTES_AC.VIEW_TRANSACTION.OVERVIEW_BY_PARTNER_LIST}
            component={AnchorOverviewByPartnerList}
          />

          {/* View Transactions - 채권 양도 통지 확인  */}
          <Route
            exact
            path={ROUTES_AC.VIEW_TRANSACTION.DEPT_SELLING_NOTICE_LIST}
            component={AnchorDeptSellingNoticeList}
          />

          <Route
            path={ROUTES_AC.VIEW_TRANSACTION.DEPT_SELLING_NOTICE_DETAIL}
            component={AnchorDeptSellingNoticeDetail}
          />

          {/* Company - User - 사용자 목록 */}
          <Route exact path={ROUTES_AC.COMPANY.USER_LIST} component={AnchorUserList} />

          {/* Company - User - 사용자 등록 */}
          <Route path={ROUTES_AC.COMPANY.USER_REGISTER} component={AnchorUserRegister} />

          {/* Company - User - 사용자 상세 */}
          <Route path={ROUTES_AC.COMPANY.USER_DETAIL} component={AnchorUserDetail} />

          {/* Company - Agreement - 약정 목록 */}
          <Route exact path={ROUTES_AC.COMPANY.AGREEMENT_LIST} component={AnchorAgreementList} />

          {/* Company - Agreement - 약정 상세 */}
          <Route path={ROUTES_AC.COMPANY.AGREEMENT_DETAIL} component={AnchorAgreementDetail} />

          {/* My Account - 내 계정 상세 */}
          <Route exact path={ROUTES_AC.MY_ACCOUNT.ACCOUNT_SETTINGS} component={AnchorMyAccount} />

          {/* My Account - email notification setting */}
          <Route
            path={ROUTES_AC.MY_ACCOUNT.EMAIL_NOTIFICATION_SETTING}
            component={AnchorMyAccountEmailNotificationSetting}
          />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </div>
    </div>
  );
}

export default AnchorRouter;
