import type Pageable from 'models/Pageable';
import type { PartialCollateralVO, PartialCollateralVOModel } from 'models/vo/PartialCollateralVO';
import { formattingToPartialCollateralVOModel } from 'models/vo/PartialCollateralVO';
import type { SuccessArDetailVO, SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import { formattingToSuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import type { SuccessArVO, SuccessArVOModel } from 'models/vo/SuccessArVO';
import { formattingToSuccessArVOModel } from 'models/vo/SuccessArVO';
import http from 'utils/http';
import API_AC from 'utils/http/api/anchor';
import type { AnchorArDeleteRequest, AnchorSuccessArsListRequest } from 'utils/http/api/anchor/success-ars/requests';

// 앵커 - 등록 완료된 AR 목록 조회
export async function requestAnchorSuccessArsList(
  pageNumber: number,
  rowCount: number,
  searchValue?: AnchorSuccessArsListRequest,
): Promise<Pageable<SuccessArVOModel[]>> {
  const response = await http.get<Pageable<SuccessArVO[]>>({
    url: API_AC.SUCCESS_ARS.SUCCESS_AR,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const successArList: SuccessArVOModel[] = response.content.map((data: SuccessArVO) =>
    formattingToSuccessArVOModel(data),
  );
  const successArPage: Pageable<SuccessArVOModel[]> = { ...response, content: successArList };

  return successArPage;
}

// 앵커 - 등록 완료된 AR 상세 조회
export async function requestAnchorSuccessArsDetail(successArId: number): Promise<SuccessArDetailVOModel> {
  const response = await http.get<SuccessArDetailVO>({
    url: API_AC.SUCCESS_ARS.SUCCESS_AR_DETAIL(successArId),
  });

  return formattingToSuccessArDetailVOModel(response);
}

// AP 삭제
export async function requestAnchorSuccessArsDelete(data: AnchorArDeleteRequest) {
  const response = await http.delete({
    url: API_AC.SUCCESS_ARS.SUCCESS_AR,
    data,
  });

  return response;
}

// 매출채권 전문 갱신
export async function requestAnchorRenewArByFinancierInterface(successArId: number) {
  const response = await http.put({
    url: API_AC.SUCCESS_ARS.RENEW_AR_BY_FINANCIER_INTERFACE(successArId),
  });

  return response;
}

export async function requestAnchorPartialCollaterals(
  pageNumber: number,
  rowCount: number,
  successArId: number,
): Promise<Pageable<PartialCollateralVOModel[]>> {
  const response = await http.get<Pageable<PartialCollateralVO[]>>({
    url: API_AC.SUCCESS_ARS.PARTIAL_COLLATERALS(successArId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map(item => formattingToPartialCollateralVOModel(item)),
  };
}
