const API_FI = {
  ACCOUNT_TRANSACTIONS: {
    ACCOUNT_TRANSACTION_LIST: (anchorAgreementId: number) => `v1/fi/account-transactions/${anchorAgreementId}`,
    SETTLEMENT_ACCOUNT_BALANCE: (anchorAgreementId: number) =>
      `v1/fi/account-transactions/${anchorAgreementId}/settlement-account-balance`,
  },
  ANCHOR_AGREEMENTS: {
    ANCHOR_AGREEMENT_LIST: 'v1/fi/anchor-agreements',
    ALL_ANCHOR_AGREEMENT_LIST: 'v1/fi/anchor-agreements/all',
    ANCHOR_AGREEMENT_LIST_FOR_PARTNERS: 'v1/fi/anchor-agreements/list-for-partners',
    ANCHOR_AGREEMENT_BY_FINANCIER_INTERFACE: (contractNo: string) =>
      `v1/fi/anchor-agreements/${contractNo}/agreement-by-financier-interface`,
    ANCHOR_AGREEMENT_INTERFACE: (anchorAgreementId: number) =>
      `v1/fi/anchor-agreements/${anchorAgreementId}/agreement-interface`,
    ANCHOR_AGREEMENT_BY_KEY_UNION_INTERFACE: 'v1/fi/anchor-agreements/agreement-by-key-union-interface',
    ANCHOR_AGREEMENT_DETAIL: (anchorAgreementId: number) => `v1/fi/anchor-agreements/${anchorAgreementId}`,
  },
  ANCHOR_PARTNER_ACCOUNTS: {
    PARTNER_BANK_ACCOUNT_LIST: 'v1/fi/anchor-partner-accounts',
    PARTNER_BANK_ACCOUNT_DETAIL: (anchorPartnerAccountId: number) =>
      `v1/fi/anchor-partner-accounts/${anchorPartnerAccountId}`,
    ASSIGN_BRANCH: 'v1/fi/anchor-partner-accounts/assign:branch',
    REINSTATE_PARTNER_ACCOUNT: (anchorPartnerAccountId: number) =>
      `v1/fi/anchor-partner-accounts/${anchorPartnerAccountId}/reinstate:account`,
    DECLINE_PARTNER_ACCOUNT: (anchorPartnerAccountId: number) =>
      `v1/fi/anchor-partner-accounts/${anchorPartnerAccountId}/decline:account`,
  },
  ANCHOR_DEALERS: {
    ANCHOR_DEALERS_LIST: 'v1/fi/anchor-dealers',
    ANCHOR_DEALERS_ASSIGN: 'v1/fi/anchor-dealers/assign',
    ANCHOR_DEALERS_DETAIL: (anchorDealerId: number) => `v1/fi/anchor-dealers/${anchorDealerId}`,
    ANCHOR_DEALERS_REINSTATE: (anchorDealerId: number) => `v1/fi/anchor-dealers/${anchorDealerId}/reinstate`,
    ANCHOR_DEALERS_DECLINE: `v1/fi/anchor-dealers/decline`,
    ANCHOR_DEALERS_CANCEL_ASSIGNMENT: (anchorDealerId: number) =>
      `v1/fi/anchor-dealers/${anchorDealerId}/cancel:assigned`,
  },
  ANCHOR_PARTNERS: {
    PARTNER_LIST: 'v1/fi/anchor-partners',
    PARTNER_DETAIL: (anchorPartnerId: number) => `v1/fi/anchor-partners/${anchorPartnerId}`,
  },
  ANCHOR_REPORT_RECEIVERS: {
    ANCHOR_REPORT_RECEIVERS: 'v1/fi/anchor-report-receivers',
    ANCHOR_REPORT_RECEIVER_DETAIL: (anchorReportReceiverId: number) =>
      `v1/fi/anchor-report-receivers/${anchorReportReceiverId}`,
    DEALER_AGREEMENT_LIST_RELATED_ANCHOR_REPORT_RECEIVER: (anchorReportReceiverId: number) =>
      `v1/fi/anchor-report-receivers/${anchorReportReceiverId}/related-agreement-list`,
    DEALER_AGREEMENT_LIST_NOT_RELATED_ANCHOR_REPORT_RECEIVER: (anchorReportReceiverId: number) =>
      `v1/fi/anchor-report-receivers/${anchorReportReceiverId}/not-related-agreement-list`,
    ASSIGN_DEALER_AGREEMENT: (anchorReportReceiverId: number) =>
      `v1/fi/anchor-report-receivers/${anchorReportReceiverId}/assign:agreement`,
    RELEASE_DEALER_AGREEMENT: (anchorReportReceiverId: number) =>
      `v1/fi/anchor-report-receivers/${anchorReportReceiverId}/release:agreement`,
  },
  ANCHOR_REPORT_SETTINGS: {
    ANCHOR_REPORT_SETTINGS: 'v1/fi/anchor-report-settings',
    ANCHOR_REPORT_SETTING_DETAIL: (anchorReportSettingId: number) =>
      `v1/fi/anchor-report-settings/${anchorReportSettingId}`,
  },
  ANCHOR_REPORTS: {
    ANCHOR_REPORT_LIST: 'v1/fi/anchor-reports',
    DOWNLOAD_ANCHOR_REPORT: (anchorReportId: number) => `v1/fi/anchor-reports/${anchorReportId}/download:report`,
  },
  ANCHOR_USERS: {
    ANCHOR_USER_LIST: 'v1/fi/anchor-users',
    ANCHOR_USER_DETAIL: (anchorUserId: number) => `v1/fi/anchor-users/${anchorUserId}`,
    INVITE_ANCHOR_USER: (anchorUserId: number) => `v1/fi/anchor-users/${anchorUserId}/invite:anchor-user`,
    ANCHOR_USER_INVITE_URL: (anchorUserId: number) => `v1/fi/anchor-users/${anchorUserId}/invite-url`,
    ANCHOR_AGREEMENT_RELATED_ANCHOR_USER: (anchorUserId: number) =>
      `v1/fi/anchor-users/${anchorUserId}/related-anchor-agreements`,
    DEALER_AGREEMENT_RELATED_ANCHOR_USER: (anchorUserId: number) =>
      `v1/fi/anchor-users/${anchorUserId}/related-dealer-agreements`,
    RELATED_USER_EMAIL_SETTING: (anchorUserId: number) =>
      `v1/fi/anchor-users/${anchorUserId}/related-user-email-setting`,
  },
  AR_APPROVAL_PHASES: {
    AR_PHASE_LIST: 'v1/fi/ar-approval-phases',
  },
  AR_COMMISSION: {
    AR_COMMISSION: 'v1/fi/ar-commissions',
    UPDATE_ALL_AR_COMMISSION_STATUS: 'v1/fi/ar-commissions/update-all:paid',
    UPDATE_AR_COMMISSION_STATUS: 'v1/fi/ar-commissions/update:status',
  },
  AR_COMMISSION_SETTINGS: {
    AR_COMMISSION_SETTINGS: 'v1/fi/ar-commission-settings',
    AR_COMMISSION_SETTINGS_DETAIL: (arCommissionSettingId: number) =>
      `v1/fi/ar-commission-settings/${arCommissionSettingId}`,
    AR_COMMISSION_SETTINGS_SPREAD: (arCommissionSettingId: number) =>
      `v1/fi/ar-commission-settings/${arCommissionSettingId}/spread`,
  },
  AR_SETTLEMENTS: {
    AR_SETTLEMENT_LIST: 'v1/fi/ar-settlements',
    AR_SETTLEMENT_DETAIL: (anchorAgreementId: number, settlementDate: string) =>
      `v1/fi/ar-settlements/${anchorAgreementId}/${settlementDate}`,
    UPDATE_SETTLED: (anchorAgreementId: number) => `v1/fi/ar-settlements/${anchorAgreementId}/update:settled`,
  },
  AR_SUMMARIES: {
    AR_SUMMARIES: 'v1/fi/ar-summaries',
    AR_SUMMARY_DETAIL: (arSummaryId: number) => `v1/fi/ar-summaries/${arSummaryId}`,
    AR_SUMMARY_FIND_LAST_PHASE_WITH_ARS: (arSummaryId: number) =>
      `v1/fi/ar-summaries/${arSummaryId}/find:last-phase-with-ars`,
    AR_SUMMARY_APPROVE: 'v1/fi/ar-summaries/approve:ar-phase',
    AR_SUMMARY_REJECT: 'v1/fi/ar-summaries/reject:ar-registration',
  },
  AR_COMMISSION_SETTLEMENTS: {
    AR_COMMISSION_SETTLEMENTS: 'v1/fi/ar-commission-settlements',
    AR_COMMISSION_SETTLEMENTS_DETAIL: (dealerAgreementId: number, settlementDate: string) =>
      `v1/fi/ar-commission-settlements/${dealerAgreementId}/${settlementDate}`,
    AR_COMMISSION_SETTLEMENT_RELATED_LOAN: 'v1/fi/ar-commission-settlements/loans',
  },
  BANK_CODE: {
    BANK_CODE_LIST: 'v1/fi/bank-code',
  },
  BASIS_INTERESTS: {
    BASIS_INTERESTS: 'v1/fi/basis-interests',
    CREATE_BASIS_INTEREST_BY_FINANCIER_INTERFACE: 'v1/fi/basis-interests/create-by-financier-interface',
    DELETE_BASIS_INTEREST: (basisInterestId: number) => `v1/fi/basis-interests/${basisInterestId}`,
  },
  BILLING: {
    BILLING_LIST: 'v1/fi/billing',
  },
  BRANCHES: {
    BRANCHES: 'v1/fi/branches',
    BRANCH_BY_FINANCIER_INTERFACE: (branchCode: string) => `v1/fi/branches/${branchCode}/branch-by-financier-interface`,
    BRANCH_DETAIL: (branchId: number) => `v1/fi/branches/${branchId}`,
    BRANCH_HISTORY_LIST: (branchId: number) => `v1/fi/branches/${branchId}/branch-history`,
  },
  COST_OF_FUNDS: {
    COST_OF_FUNDS_DETAIL: (id: number) => `v1/fi/cost-of-funds/${id}`,
  },
  CLIENT_AUTH_SETTING: {
    AUTH_SETTING: 'v1/fi/client-auth-setting',
  },
  DASHBOARD: {
    CLIENT_REQUEST: 'v1/fi/dashboard/client-request',
    INTERNAL_APPROVAL: 'v1/fi/dashboard/internal-approval',
    TASK_SCHEDULE: 'v1/fi/dashboard/task-schedule',
    PERFORMANCE: 'v1/fi/dashboard/performance',
  },
  DASHBOARD_POTENTIAL_PARTNER_FINANCING: {
    DASHBOARD_POTENTIAL_PARTNER_FINANCING: 'v1/fi/dashboard-potential-partner-financing',
  },
  DEALER_AGREEMENT_REPAID_AMOUNTS: {
    DEALER_AGREEMENT_REPAID_AMOUNT_LIST: 'v1/fi/dealer-agreement-repaid-amounts',
  },
  DEALER_AGREEMENTS: {
    DEALER_AGREEMENT_LIST: 'v1/fi/dealer-agreements',
    DEALER_AGREEMENT_DETAIL: (dealerAgreementId: number) => `v1/fi/dealer-agreements/${dealerAgreementId}`,
    DEALER_AGREEMENT_BY_FINANCIER_INTERFACE: (contractNo: string) =>
      `v1/fi/dealer-agreements/${contractNo}/agreement-by-financier-interface`,
    DEALER_AGREEMENT_INTERFACE: (dealerAgreementId: number) =>
      `v1/fi/dealer-agreements/${dealerAgreementId}/agreement-interface`,
    DEALER_AGREEMENT_BY_KEY_UNION_INTERFACE: 'v1/fi/dealer-agreements/agreement-by-key-union-interface',
    TEMP_DEALER_AGREEMENT_LIST: 'v1/fi/dealer-agreements/list-for-temp-invoice',
    ANCHOR_AGREEMENT_RELATED_DEALER_AGREEMENT: (dealerAgreementId: number) =>
      `v1/fi/dealer-agreements/${dealerAgreementId}/related-anchor-agreement`,
    ANCHOR_CLIENT_LIMIT_INFO_RELATED_DEALER_AGREEMENT: (dealerAgreementId: number) =>
      `v1/fi/dealer-agreements/${dealerAgreementId}/related-anchor-client-limit-info`,
    DEALER_INVITE_URL: (dealerAgreementId: number) => `v1/fi/dealer-agreements/${dealerAgreementId}/invite-url`,
  },
  DELETED_ARS: {
    DELETED_ARS: 'v1/fi/deleted-ars',
  },
  DELETED_INVOICES: {
    DELETED_INVOICES: 'v1/fi/deleted-invoices',
  },
  DIVISIONS: {
    DIVISIONS: 'v1/fi/divisions',
    DIVISION_BY_CODE: 'v1/fi/divisions/find:by-code',
    DIVISION_DETAIL: (divisionId: number) => `v1/fi/divisions/${divisionId}`,
  },
  EARLY_REPAYMENT_REQUESTS: {
    EARLY_REPAYMENT_LIST: 'v1/fi/early-repayment-requests',
    EARLY_REPAYMENT_DETAIL: (earlyRepaymentId: number) => `v1/fi/early-repayment-requests/${earlyRepaymentId}`,
    COMPLETE_EARLY_REPAYMENT: (earlyRepaymentId: number) =>
      `v1/fi/early-repayment-requests/${earlyRepaymentId}/complete:loan-early-repayment`,
    EXPIRE_EARLY_REPAYMENT: (earlyRepaymentId: number) =>
      `v1/fi/early-repayment-requests/${earlyRepaymentId}/expire:loan-early-repayment`,
  },
  ENTERPRISES: {
    USER_LIST: 'v1/fi/enterprises/current-enterprise/users',
  },
  EXTENSION_REQUESTS: {
    EXTENSION_LIST: 'v1/fi/extension-requests',
    EXTENSION_DETAIL: (extensionRequestId: number) => `v1/fi/extension-requests/${extensionRequestId}`,
    RETURN_EXTENSION: (extensionRequestId: number) =>
      `v1/fi/extension-requests/${extensionRequestId}/return:loan-extension`,
    APPROVE_EXTENSION: (extensionRequestId: number) =>
      `v1/fi/extension-requests/${extensionRequestId}/approve:loan-extension`,
    REJECT_EXTENSION: (extensionRequestId: number) =>
      `v1/fi/extension-requests/${extensionRequestId}/reject:loan-extension`,
    RENEW_EXTENSION: (extensionRequestId: number) =>
      `v1/fi/extension-requests/${extensionRequestId}/renew:loan-extension-by-financier-interface`,
    REQUEST_EXTENSION_CONFIRM: (extensionRequestId: number) =>
      `v1/fi/extension-requests/${extensionRequestId}/request:loan-extension`,
  },
  FACTORING_NOTICES: {
    FACTORING_NOTICE: `v1/fi/factoring-notices`,
    FACTORING_NOTICE_DETAIL: (factoringNoticeId: number) => `v1/fi/factoring-notices/${factoringNoticeId}`,
    FACTORING_NOTICE_DOWNLOAD_ATTACHMENT: (factoringNoticeId: number) =>
      `v1/fi/factoring-notices/${factoringNoticeId}/download:attachment`,
    FACTORING_NOTICE_RECEIVERS: (factoringNoticeId: number) => `v1/fi/factoring-notices/${factoringNoticeId}/receivers`,
    FACTORING_NOTICE_RELATED_ARS: (factoringNoticeId: number) =>
      `v1/fi/factoring-notices/${factoringNoticeId}/related-ars`,
    FACTORING_NOTICE_RESEND: (factoringNoticeId: number) => `v1/fi/factoring-notices/${factoringNoticeId}/resend`,
  },
  FINANCIER_ALERT_SETTING: {
    ALERT_SETTINGS: 'v1/fi/financier-alert-setting',
    UPDATE_RELATED_USERS: 'v1/fi/financier-alert-setting/update:related-users',
  },
  FINANCIER_CALENDAR: {
    FINANCIER_CALENDAR_LIST: 'v1/fi/financier-calendar',
    INSERT_HOLIDAY_BY_INTERFACE: 'v1/fi/financier-calendar/insert:holiday-by-financier-interface',
    INSERT_HOLIDAY_BY_DAY_OF_WEEK: 'v1/fi/financier-calendar/insert:holiday-by-day-of-week',
    INSERT_HOLIDAY_BY_SPECIFIC_DATES: 'v1/fi/financier-calendar/insert:holiday-by-specific-dates',
    FINANCIER_CALENDAR_DETAIL: (financierId: number) => `v1/fi/financier-calendar/${financierId}`,
  },
  FINANCIER_CLIENT_HISTORIES: {
    CLIENT_HISTORY_LIST: 'v1/fi/financier-client-histories',
  },
  FINANCIER_CLIENTS: {
    CLIENTS: 'v1/fi/financier-clients',
    CLIENT_DETAIL: (financierClientId: number) => `v1/fi/financier-clients/${financierClientId}`,
    CLIENT_BY_FINANCIER_INTERFACE_CODE: (financierClientCode: string) =>
      `v1/fi/financier-clients/${financierClientCode}/client-by-financier-interface:code`,
    CLIENT_BY_FINANCIER_INTERFACE_ID: (financierClientCode: number) =>
      `v1/fi/financier-clients/${financierClientCode}/client-by-financier-interface:id`,
  },
  FINANCIER_COMMON_SETTING: {
    COMMON_SETTING_LIST: 'v1/fi/financier-common-setting',
    COMMON_SETTING_DETAIL: (financierCommonSettingId: number) =>
      `v1/fi/financier-common-setting/${financierCommonSettingId}`,
  },
  FINANCIER_CURRENCY_COMMON_SETTING: {
    CURRENCY_COMMON_SETTINGS: 'v1/fi/financier-currency-common-setting',
    CURRENCY_COMMON_SETTING_DETAIL: (financierCurrencyCommonSettingId: number) =>
      `v1/fi/financier-currency-common-setting/${financierCurrencyCommonSettingId}`,
  },
  FINANCIER_DOCUMENT_FORMS: {
    DOCUMENT_FORMS: 'v1/fi/financier-document-forms',
    DOCUMENT_DETAIL: (financierDocumentFormId: number) => `v1/fi/financier-document-forms/${financierDocumentFormId}`,
    DOWNLOAD_ATTACHMENT: (financierDocumentFormId: number) =>
      `v1/fi/financier-document-forms/${financierDocumentFormId}/download:attachment`,
  },
  FINANCIER_OPERATION_TIME: {
    OPERATION_TIME: 'v1/fi/financier-operation-time',
  },
  INVENTORY_SUMMARIES: {
    INVENTORY_LIST: 'v1/fi/inventory-summaries',
    INVENTORY_DETAIL: (inventorySummaryId: number) => `v1/fi/inventory-summaries/${inventorySummaryId}`,
    DOWNLOAD_ATTACHMENT: (inventorySummaryId: number) =>
      `v1/fi/inventory-summaries/${inventorySummaryId}/download:attachment`,
  },
  INVOICE_APPROVAL_PHASES: {
    INVOICE_PHASE_LIST: 'v1/fi/invoice-approval-phases',
  },
  INVOICE_PAYMENTS: {
    INVOICE_PAYMENT_LIST: 'v1/fi/invoice-payments',
  },
  INVOICE_SUMMARIES: {
    INVOICE_SUMMARY: 'v1/fi/invoice-summaries',
    INVOICE_SUMMARY_DETAIL: (invoiceSummaryId: number) => `v1/fi/invoice-summaries/${invoiceSummaryId}`,
    INVOICE_SUMMARY_LAST_PHASE: (invoiceSummaryId: number) =>
      `v1/fi/invoice-summaries/${invoiceSummaryId}/find:last-phase-with-invoices`,
    APPROVE_INVOICE_PHASE: 'v1/fi/invoice-summaries/approve:invoice-phase',
    REJECT_INVOICE_REGISTRATION: 'v1/fi/invoice-summaries/reject:invoice-registration',
  },
  LOANS: {
    LOAN_LIST: 'v1/fi/loans',
    LOAN_DETAIL: (loanId: number) => `v1/fi/loans/${loanId}`,
    DOWNLOAD_LOAN_AGREEMENT: (loanId: number) => `v1/fi/loans/${loanId}/download:loan-agreement`,
    DOWNLOAD_LOAN_ETC: (loanId: number) => `v1/fi/loans/${loanId}/download:loan-etc`,
    DOWNLOAD_LOAN_REQUEST_FORM: (loanId: number) => `v1/fi/loans/${loanId}/download:loan-request-form`,
    REQUEST_LOAN_DISBURSEMENT: (loanId: number) => `v1/fi/loans/${loanId}/request:loan-disbursement`,
    APPROVE_LOAN_DISBURSEMENT: (loanId: number) => `v1/fi/loans/${loanId}/approve:loan-disbursement`,
    REJECT_LOAN_DISBURSEMENT: (loanId: number) => `v1/fi/loans/${loanId}/reject:loan-disbursement`,
    EXECUTE_LOAN_DISBURSEMENT: (loanId: number) => `v1/fi/loans/${loanId}/execute:loan-disbursement`,
    REQUEST_CANCEL_LOAN_DISBURSEMENT: (loanId: number) => `v1/fi/loans/${loanId}/request:cancel-disbursement`,
    APPROVE_CANCEL_LOAN_DISBURSEMENT: (loanId: number) => `v1/fi/loans/${loanId}/approve:cancel-disbursement`,
    LOAN_TRANSACTIONS: (loanId: number) => `v1/fi/loans/${loanId}/loan-transactions`,
    RENEW_LOAN_BY_FINANCIER_INTERFACE: (loanId: number) => `v1/fi/loans/${loanId}/renew:loan-by-financier-interface`,
    RETURN_LOAN: (loanId: number) => `v1/fi/loans/${loanId}/return:loan`,
    CANCEL_LOAN: (loanId: number) => `v1/fi/loans/${loanId}/cancel:loan`,
    REQUEST_LOAN_REPAYMENT: (loanId: number) => `v1/fi/loans/${loanId}/request:loan-repayment`,
    APPROVE_LOAN_REPAYMENT: (loanId: number) => `v1/fi/loans/${loanId}/approve:loan-repayment`,
    REQUEST_LOAN_OVERDUE: (loanId: number) => `v1/fi/loans/${loanId}/request:loan-overdue`,
    APPROVE_LOAN_OVERDUE: (loanId: number) => `v1/fi/loans/${loanId}/approve:loan-overdue`,
    REQUEST_LOAN_EXPIRATION: (loanId: number) => `v1/fi/loans/${loanId}/request:loan-expiration`,
    APPROVE_LOAN_EXPIRATION: (loanId: number) => `v1/fi/loans/${loanId}/approve:loan-expiration`,
    REQUEST_LOAN_OVERDUE_RELEASE: (loanId: number) => `v1/fi/loans/${loanId}/request:loan-overdue-release`,
    APPROVE_LOAN_OVERDUE_RELEASE: (loanId: number) => `v1/fi/loans/${loanId}/approve:loan-overdue-release`,
    REQUEST_APPROVED_LOAN_REJECTION: (loanId: number) => `v1/fi/loans/${loanId}/request:approved-loan-rejection`,
    APPROVE_APPROVED_LOAN_REJECTION: (loanId: number) => `v1/fi/loans/${loanId}/approve:approved-loan-rejection`,
    DOWNLOAD_SCANNED_AR: (loanId: number) => `v1/fi/loans/${loanId}/download:scanned-ar`,
    CALCULATE_EXPECTED_AMOUNT: (loanId: number) => `v1/fi/loans/${loanId}/calculate:expected-amount`,
    CALCULATE_NET_DISBURSEMENT: (loanId: number) => `v1/fi/loans/${loanId}/calculate:net-disbursement`,
    DOWNLOAD_DEBT_SELLING_NOTIFICATION: (loanId: number) =>
      `v1/fi/loans/${loanId}/download:loan-factoring-notification`,
    DOWNLOAD_FACTORING_DOCS_FOR_ESIGN: (loanId: number) => `v1/fi/loans/${loanId}/download:factoring-docs-for-esign`,
    LOAN_ADDITIONAL_DOCUMENT_ATTACHMENTS: (loanId: number) => `v1/fi/loans/${loanId}/additional-document-attachments`,
  },
  LOAN_ADDITIONAL_DOCUMENT_ATTACHMENTS: {
    REGISTER_LOAN_ADDITIONAL_DOCUMENTS: 'v1/fi/loan-additional-documents',
    MODIFY_LOAN_ADDITIONAL_DOCUMENTS: (documentId: number) => `v1/fi/loan-additional-documents/${documentId}`,
    DELETE_LOAN_ADDITIONAL_DOCUMENTS: (documentId: number) => `v1/fi/financier-document-forms/${documentId}`,
    LOAN_ADDITIONAL_DOCUMENTS_DETAIL: `v1/fi/loan-additional-documents/find:by-form-id`,
  },
  MULTIPLE_REQUEST_LOANS: {
    MULTIPLE_REQUEST_LOAN_LIST: 'v1/fi/multiple-request-loans',
    MULTIPLE_REQUEST_LOAN_DETAIL: (multipleRequestLoansId: number) =>
      `v1/fi/multiple-request-loans/${multipleRequestLoansId}`,
    MULTIPLE_REQUEST_LOAN_AR_LIST: (multipleRequestLoansId: number) =>
      `v1/fi/multiple-request-loans/${multipleRequestLoansId}/ars`,
    MULTIPLE_REQUEST_LOAN_RELATED_FINANCING_LIST: (multipleRequestLoansId: number) =>
      `v1/fi/multiple-request-loans/${multipleRequestLoansId}/loans`,
    DOWNLOAD_ATTACHMENT: (multipleRequestLoansId: number) =>
      `v1/fi/multiple-request-loans/${multipleRequestLoansId}/download:attachment`,
    DOWNLOAD_ATTACHMENT_FOR_ESIGN: (multipleRequestLoansId: number) =>
      `v1/fi/multiple-request-loans/${multipleRequestLoansId}/download:attachment-for-esign`,
    REQUEST: (multipleRequestLoansId: number) => `v1/fi/multiple-request-loans/${multipleRequestLoansId}/request`,
    CANCEL: (multipleRequestLoansId: number) => `v1/fi/multiple-request-loans/${multipleRequestLoansId}/cancel`,
    APPROVE: (multipleRequestLoansId: number) => `v1/fi/multiple-request-loans/${multipleRequestLoansId}/approve`,
    REJECT: (multipleRequestLoansId: number) => `v1/fi/multiple-request-loans/${multipleRequestLoansId}/reject`,
    RETURN: (multipleRequestLoansId: number) => `v1/fi/multiple-request-loans/${multipleRequestLoansId}/return`,
    REQUEST_APPROVED_LOAN_REJECTION: (multipleRequestLoansId: number) =>
      `v1/fi/multiple-request-loans/${multipleRequestLoansId}/request:approved-multiple-loan-rejection`,
    APPROVE_APPROVED_LOAN_REJECTION: (multipleRequestLoansId: number) =>
      `v1/fi/multiple-request-loans/${multipleRequestLoansId}/approve:approved-multiple-loan-rejection`,
    LOAN_ADDITIONAL_DOCUMENT_ATTACHMENTS: (multipleRequestLoansId: number) =>
      `v1/fi/multiple-request-loans/${multipleRequestLoansId}/additional-document-attachments`,
  },
  LOAN_ADDITIONAL_DOCUMENT_ATTACHMENT: {
    DOWNLOAD_ATTACHMENT: (documentAttachmentId: number) =>
      `v1/fi/loan-additional-document-attachments/${documentAttachmentId}/download`,
  },
  PARTNER_USERS: {
    PARTNER_USERS_LIST: 'v1/fi/partner-users',
    PARTNER_USERS_FIND_BY_DEALER_AGREEMENT_ID: `v1/fi/partner-users/find:by-dealer-agreement-id`,
    RELATED_USER_EMAIL_SETTING: (userId: number) => `v1/fi/partner-users/${userId}/related-user-email-setting`,
  },
  POTENTIAL_PARTNER_ACQUISITION_DOCUMENTS: {
    ACQUISITION_DOCUMENTS: 'v1/fi/potential-partner-acquisition-documents',
    ACQUISITION_DOCUMENT_DETAIL: (potentialPartnerAcquisitionDocumentSettingId: number) =>
      `v1/fi/potential-partner-acquisition-documents/${potentialPartnerAcquisitionDocumentSettingId}`,
    DOWNLOAD_ATTACHMENT: (potentialPartnerAcquisitionDocumentSettingId: number) =>
      `v1/fi/potential-partner-acquisition-documents/${potentialPartnerAcquisitionDocumentSettingId}/download:attachment`,
  },
  POTENTIAL_PARTNER_ACQUISITION_SETTING: {
    ACQUISITION_SETTING: `v1/fi/potential-partner-acquisition-setting`,
  },
  POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENTS: {
    APPLICATION_DOCUMENT_LIST: 'v1/fi/potential-partner-financing-application-documents',
    DOWNLOAD_ATTACHMENT: (potentialPartnerFinancingApplicationDocumentId: number) =>
      `v1/fi/potential-partner-financing-application-documents/${potentialPartnerFinancingApplicationDocumentId}/download:attachment`,
    UPDATE_DOCUMENT_STATUS: (potentialPartnerFinancingApplicationDocumentId: number) =>
      `v1/fi/potential-partner-financing-application-documents/${potentialPartnerFinancingApplicationDocumentId}/update:document-status`,
  },
  POTENTIAL_PARTNER_FINANCING_APPLICATIONS: {
    FINANCING_APPLICATION_LIST: 'v1/fi/potential-partner-financing-applications',
    FINANCING_APPLICATION_DETAIL: (potentialPartnerFinancingApplicationId: number) =>
      `v1/fi/potential-partner-financing-applications/${potentialPartnerFinancingApplicationId}`,
    ACTIVATED_APPLICATION: (potentialPartnerFinancingApplicationId: number) =>
      `v1/fi/potential-partner-financing-applications/${potentialPartnerFinancingApplicationId}/activated-application`,
    POTENTIAL_PARTNER_DETAIL: (potentialPartnerFinancingApplicationId: number) =>
      `v1/fi/potential-partner-financing-applications/${potentialPartnerFinancingApplicationId}/potential-partner`,
    ANCHOR_AGREEMENT_RELATED_POTENTIAL_PARTNER: (potentialPartnerFinancingApplicationId: number) =>
      `v1/fi/potential-partner-financing-applications/${potentialPartnerFinancingApplicationId}/anchor-agreement`,
    PARTNER_AGREEMENT_RELATED_POTENTIAL_PARTNER: (potentialPartnerFinancingApplicationId: number) =>
      `v1/fi/potential-partner-financing-applications/${potentialPartnerFinancingApplicationId}/partner-agreement`,
    REVIEW_SUBMITTED_FINANCING_APPLICATION: (potentialPartnerFinancingApplicationId: number) =>
      `v1/fi/potential-partner-financing-applications/${potentialPartnerFinancingApplicationId}/review:submitted`,
    CANCEL_IN_PROGRESS_FINANCING_APPLICATION: (potentialPartnerFinancingApplicationId: number) =>
      `v1/fi/potential-partner-financing-applications/${potentialPartnerFinancingApplicationId}/cancel:in-progress`,
    REJECT_IN_PROGRESS_FINANCING_APPLICATION: (potentialPartnerFinancingApplicationId: number) =>
      `v1/fi/potential-partner-financing-applications/${potentialPartnerFinancingApplicationId}/reject:in-progress`,
    CANCEL_FINANCING_APPLICATION_REJECTION: (potentialPartnerFinancingApplicationId: number) =>
      `v1/fi/potential-partner-financing-applications/${potentialPartnerFinancingApplicationId}/cancel:rejected`,
  },
  PURPOSE_OF_LOANS: {
    DOWNLOAD_PURPOSE_ATTACHMENT: (purposeOfLoanId: number) =>
      `v1/fi/purpose-of-loans/${purposeOfLoanId}/download:purpose-attachment`,
    DOWNLOAD_ACCOUNT_ATTACHMENT: (purposeOfLoanId: number) =>
      `v1/fi/purpose-of-loans/${purposeOfLoanId}/download:account-attachment`,
  },
  STATISTICS_OF_ANCHOR_AGREEMENTS: {
    STATISTICS_OF_ANCHOR_AGREEMENT_LIST: 'v1/fi/statistics-of-anchor-agreements',
  },
  STATISTICS_OF_DEALER_AGREEMENTS: {
    STATISTICS_OF_DEALER_AGREEMENT_LIST: 'v1/fi/statistics-of-dealer-agreements',
  },
  SUCCESS_ARS: {
    SUCCESS_AR: 'v1/fi/success-ars',
    SUCCESS_AR_DETAIL: (successArId: number) => `v1/fi/success-ars/${successArId}`,
    BLOCK_APPLYING_FOR_LOAN: (successArId: number) => `v1/fi/success-ars/${successArId}/block:applying-for-loan`,
    UNBLOCK_APPLYING_FOR_LOAN: (successArId: number) => `v1/fi/success-ars/${successArId}/unblock:applying-for-loan`,
    RENEW_AR_BY_FINANCIER_INTERFACE: (successArId: number) =>
      `v1/fi/success-ars/${successArId}/renew:ar-by-financier-interface`,
    UPDATE_STATUS: (successArId: number) => `v1/fi/success-ars/${successArId}/update:status`,
    PARTIAL_COLLATERALS: (successArId: number) => `v1/fi/success-ars/${successArId}/partial-collaterals`,
  },
  SUCCESS_INVOICES: {
    SUCCESS_INVOICE: 'v1/fi/success-invoices',
    SUCCESS_INVOICE_DETAIL: (successInvoiceId: number) => `v1/fi/success-invoices/${successInvoiceId}`,
    DOWNLOAD_SUCCESS_INVOICE: (successInvoiceId: number) =>
      `v1/fi/success-invoices/${successInvoiceId}/download:scanned-invoice`,
    BLOCK_APPLYING_FOR_LOAN: (successInvoiceId: number) =>
      `v1/fi/success-invoices/${successInvoiceId}/block:applying-for-loan`,
    UNBLOCK_APPLYING_FOR_LOAN: (successInvoiceId: number) =>
      `v1/fi/success-invoices/${successInvoiceId}/unblock:applying-for-loan`,
  },
  TEMP_WAITING_ANCHOR_AGREEMENTS: {
    TEMP_WAITING_ANCHOR_AGREEMENTS: 'v1/fi/temp-waiting-anchor-agreements',
  },
  TEMP_WAITING_DEALER_AGREEMENTS: {
    TEMP_WAITING_DEALER_AGREEMENTS: 'v1/fi/temp-waiting-dealer-agreements',
  },
  USAGE: {
    USAGE: 'v1/fi/usage',
  },
  USERS: {
    USER: 'v1/fi/users',
    USER_DETAIL: (userId: number) => `v1/fi/users/${userId}`,
    USER_BY_FINANCIER_INTERFACE: (userCode: string) => `v1/fi/users/${userCode}/user-by-financier-interface`,
    SUSPEND_FINANCIER_USER: (userId: number) => `v1/fi/users/${userId}/suspend:financier-user`,
    INVITE_FINANCIER_USER: (userId: number) => `v1/fi/users/${userId}/invite:financier-user`,
    INVITE_CLIENT_AUTHORIZER: 'v1/fi/users/invite:client-authorizer',
    INVITE_DEALER_CLIENT_AUTHORIZER: 'v1/fi/users/invite:dealer-client-authorizer',
    INVITE_URL: (userId: number) => `v1/fi/users/${userId}/invite-url`,
  },
  WAITING_ANCHOR_AGREEMENTS: {
    WAITING_ANCHOR_AGREEMENT: 'v1/fi/waiting-anchor-agreements',
    WAITING_ANCHOR_AGREEMENT_DETAIL: (waitingAnchorAgreementId: number) =>
      `v1/fi/waiting-anchor-agreements/${waitingAnchorAgreementId}`,
    CANCEL_WAITING_ANCHOR_AGREEMENT: (waitingAnchorAgreementId: number) =>
      `v1/fi/waiting-anchor-agreements/${waitingAnchorAgreementId}/cancel:modification`,
    RETURN_WAITING_ANCHOR_AGREEMENT: (waitingAnchorAgreementId: number) =>
      `v1/fi/waiting-anchor-agreements/${waitingAnchorAgreementId}/return:modification`,
    APPROVE_WAITING_ANCHOR_AGREEMENT: (waitingAnchorAgreementId: number) =>
      `v1/fi/waiting-anchor-agreements/${waitingAnchorAgreementId}/approve:modification`,
  },
  WAITING_ANCHOR_PARTNER_ACCOUNTS: {
    WAITING_PARTNER_ACCOUNT: 'v1/fi/waiting-anchor-partner-accounts',
    WAITING_PARTNER_ACCOUNT_DETAIL: (waitingAnchorPartnerAccountId: number) =>
      `v1/fi/waiting-anchor-partner-accounts/${waitingAnchorPartnerAccountId}`,
    APPROVE_WAITING_PARTNER_ACCOUNT: (waitingAnchorPartnerAccountId: number) =>
      `v1/fi/waiting-anchor-partner-accounts/${waitingAnchorPartnerAccountId}/approve:modification`,
    RETURN_WAITING_PARTNER_ACCOUNT: (waitingAnchorPartnerAccountId: number) =>
      `v1/fi/waiting-anchor-partner-accounts/${waitingAnchorPartnerAccountId}/return:modification`,
    CANCEL_WAITING_PARTNER_ACCOUNT: (waitingAnchorPartnerAccountId: number) =>
      `v1/fi/waiting-anchor-partner-accounts/${waitingAnchorPartnerAccountId}/cancel:modification`,
  },
  WAITING_ANCHOR_PARTNERS: {
    WAITING_ANCHOR_PARTNER: 'v1/fi/waiting-anchor-partners',
    WAITING_ANCHOR_PARTNER_DETAIL: (waitingAnchorPartnerId: number) =>
      `v1/fi/waiting-anchor-partners/${waitingAnchorPartnerId}`,
    REGISTER_WAITING_ANCHOR_PARTNER: (waitingAnchorPartnerId: number) =>
      `v1/fi/waiting-anchor-partners/${waitingAnchorPartnerId}/update:registration`,
    APPROVE_WAITING_ANCHOR_PARTNER: 'v1/fi/waiting-anchor-partners/approve:registration',
    CANCEL_WAITING_ANCHOR_PARTNER: 'v1/fi/waiting-anchor-partners/cancel:modification',
    RETURN_WAITING_ANCHOR_PARTNER: 'v1/fi/waiting-anchor-partners/return:modification',
  },
  WAITING_ANCHOR_USERS: {
    WAITING_ANCHOR_USERS: 'v1/fi/waiting-anchor-users',
    WAITING_ANCHOR_USER_DETAIL: (id: number) => `v1/fi/waiting-anchor-users/${id}`,
    RETURN_USER_MODIFICATION: (id: number) => `v1/fi/waiting-anchor-users/${id}/return:modification`,
    APPROVE_USER: (id: number) => `v1/fi/waiting-anchor-users/${id}/approve:modification`,
    CANCEL_USER: (id: number) => `v1/fi/waiting-anchor-users/${id}/cancel:modification`,
  },
  WAITING_ARS: {
    WAITING_AR_LIST: 'v1/fi/waiting-ars',
  },
  WAITING_BANK_CODE: {
    WAITING_BANK_CODE: `v1/fi/waiting-bank-code`,
    WAITING_BANK_CODE_DETAIL: (waitingBankCodeId: number) => `v1/fi/waiting-bank-code/${waitingBankCodeId}`,
    CANCEL_WAITING_BANK_CODE: (waitingBankCodeId: number) =>
      `v1/fi/waiting-bank-code/${waitingBankCodeId}/cancel:modification`,
  },
  WAITING_DEALER_AGREEMENTS: {
    WAITING_DEALER_AGREEMENTS: 'v1/fi/waiting-dealer-agreements',
    WAITING_DEALER_AGREEMENT_DETAIL: (waitingDealerAgreementId: number) =>
      `v1/fi/waiting-dealer-agreements/${waitingDealerAgreementId}`,
    RETURN_WAITING_DEALER_AGREEMENT: (waitingDealerAgreementId: number) =>
      `v1/fi/waiting-dealer-agreements/${waitingDealerAgreementId}/return:modification`,
    CANCEL_WAITING_DEALER_AGREEMENT: (waitingDealerAgreementId: number) =>
      `v1/fi/waiting-dealer-agreements/${waitingDealerAgreementId}/cancel:modification`,
    APPROVE_WAITING_DEALER_AGREEMENT: (waitingDealerAgreementId: number) =>
      `v1/fi/waiting-dealer-agreements/${waitingDealerAgreementId}/approve:modification`,
  },
  WAITING_INVOICES: {
    WAITING_INVOICE_LIST: 'v1/fi/waiting-invoices',
  },
  WAITING_ANCHOR_DEALERS: {
    WAITING_ANCHOR_DEALERS: 'v1/fi/waiting-anchor-dealers',
    WAITING_ANCHOR_DEALERS_DETAIL: (waitingAnchorDealerId: number) =>
      `v1/fi/waiting-anchor-dealers/${waitingAnchorDealerId}`,
    WAITING_ANCHOR_DEALERS_CANCEL: 'v1/fi/waiting-anchor-dealers/cancel',
    WAITING_ANCHOR_DEALERS_REJECT: 'v1/fi/waiting-anchor-dealers/reject',
    WAITING_ANCHOR_DEALERS_APPROVE_REGISTER: 'v1/fi/waiting-anchor-dealers/approve:register',
  },
  OVERDUE_INTEREST_RATE_SETTINGS: {
    OVERDUE_INTEREST_RATE_SETTINGS: 'v1/fi/overdue-interest-rate-settings',
    OVERDUE_INTEREST_RATE_SETTINGS_DETAIL: (overdueInterestRateSettingId: number) =>
      `v1/fi/overdue-interest-rate-settings/${overdueInterestRateSettingId}`,
    OVERDUE_INTEREST_RATE_SETTINGS_TERM_SPREAD: (overdueInterestRateSettingId: number) =>
      `v1/fi/overdue-interest-rate-settings/${overdueInterestRateSettingId}/term-spread`,
    OVERDUE_INTEREST_RATE_SETTINGS_COST_OF_FUND: (overdueInterestRateSettingId: number) =>
      `v1/fi/overdue-interest-rate-settings/${overdueInterestRateSettingId}/cost-of-fund`,
    OVERDUE_INTEREST_RATE_SETTINGS_HISTORY: (overdueInterestRateSettingId: number) =>
      `v1/fi/overdue-interest-rate-settings/${overdueInterestRateSettingId}/histories`,
    OVERDUE_INTEREST_RATE_TERM_SPREAD_GROUPS: (overdueInterestRateTermSpreadGroupId: number) =>
      `v1/fi/overdue-interest-rate-term-spread-groups/${overdueInterestRateTermSpreadGroupId}`,
  },
};

export default API_FI;
